<template>
  <div class="contactus_box main">
    <div class="container content">
      <div class="title">Contact us</div>
      <div class="cu_content">
        <div class="text">
          Alternatively, please feel free to email us at
          <a href="mailto:international@eciawards.org"
            ><u>international@eciawards.org</u></a
          >.
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <div class="box1">
            <div class="left">
              <el-form-item label="First Name" prop="first_name">
                <el-input v-model="ruleForm.first_name"></el-input>
              </el-form-item>
              <el-form-item label="Email" prop="email">
                <el-input v-model="ruleForm.email"></el-input>
              </el-form-item>
              <el-form-item
                label="Company Name"
                prop="company_name"
                label-width="150"
              >
                <el-input v-model="ruleForm.company_name"></el-input>
              </el-form-item>
              <el-form-item label="Category" prop="category">
                <el-input v-model="ruleForm.category"></el-input>
              </el-form-item>
            </div>
            <div class="right">
              <el-form-item
                label="Last Name"
                prop="last_name"
                label-width="150"
              >
                <el-input v-model="ruleForm.last_name"></el-input>
              </el-form-item>
              <el-form-item
                label="Phone Number"
                prop="phone_number"
                label-width="150"
              >
                <el-input v-model="ruleForm.phone_number"></el-input>
              </el-form-item>
              <el-form-item
                label="Country Based In"
                prop="country"
                label-width="150"
              >
                <el-input v-model="ruleForm.country"></el-input>
              </el-form-item>
              <el-form-item label="SubCategory" prop="subcategory">
                <el-input v-model="ruleForm.subcategory"></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="box2">
            <el-form-item
              label="Tell us a bit more about your issue"
              class="textarea"
              prop="issue"
            >
              <el-input type="textarea" v-model="ruleForm.issue"></el-input>
            </el-form-item>
          </div>
          <div class="button submit" @click="submitForm('ruleForm')">
            SUBMIT
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { CONTACT } from '@/api/footer.js';
export default {
  name: 'Contactus',
  components: {},
  data() {
    return {
      checkgroup: {},
      ruleForm: {
        first_name: '',
        text: '',
      },
      rules: {
        first_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        last_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        email: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        phone_number: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        company_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        country: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        // category: [
        //   { required: true, message: 'Please enter', trigger: 'blur' },
        // ],
        // subcategory: [
        //   { required: true, message: 'Please enter', trigger: 'blur' },
        // ],
        issue: [{ required: true, message: 'Please enter', trigger: 'blur' }],
      },
    };
  },
  created() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        console.log(valid);
        if (valid) {
          CONTACT(this.ruleForm).then(res => {
            this.$message({
              type: 'success',
              message: 'success',
            });
          });
        } else {
          console.log('error submit!!');
          this.$message({
            type: 'warning',
            message: 'Please complete',
          });
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contactus_box {
  .submit {
    margin: 0;
  }
  .title {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .cu_content {
    width: 820px;
    margin: 0 auto;

    .box1 {
      margin-top: 30px;
      justify-content: space-between;
      display: flex;
      .left,
      .right {
        width: 45%;
      }
    }
  }
}
@media (max-width: 1000px) {
  .contactus_box {
    .cu_content {
      width: 100%;
      .box1 {
        display: block;
        .left,
        .right {
          width: 100%;
        }
      }
    }
  }
}
</style>
